import {
  addDays,
  differenceInCalendarWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  getDay,
  isBefore,
  isSameDay,
  startOfWeek,
} from "date-fns";
import {
  convertUTCtoLocaleReadable,
  utcToLocaleDateTime,
} from "@/utils/dateFormat.util.ts";
import { locale } from "@/locales";

/**'
 * Convert date  to desired format
 * @param {Date} date
 * @param dateTimeFormat
 * @return {string} formatted date
 */
export const formatDateTime = (
  date: Date,
  dateTimeFormat = "dd.MM.yyyy HH:mm"
): string => {
  if (!date) return "";
  return format(date, dateTimeFormat);
};

export const timeFormatToShow = (dateTime: string) => {
  if (!dateTime) return "";
  /*
   * Will implement after discussion
   * */
  return convertUTCtoLocaleReadable(
    dateTime,
    locale === "en" ? "en-US" : "no-NO"
  );
};
/**
 * Removed GMT and beyond for make sure its not manipulating timezone while new Date
 * @param {string} dateString -string which need to be removed
 * @returns {string} formatted str
 */
export const removeGMTAndBeyond = (dateString) => {
  return dateString ? dateString.replace(/GMT.*/, "").trim() : "";
};

export const isoFormatDateTime = (date: Date | string) => {
  let converted;
  if (typeof date === "string") {
    converted = new Date(removeGMTAndBeyond(date));
  } else {
    converted = new Date(date);
  }
  return `${converted.getFullYear()}-${setLeadingZero(
    converted.getMonth() + 1
  )}-${setLeadingZero(converted.getDate())}T${setLeadingZero(
    converted.getHours()
  )}:${setLeadingZero(converted.getMinutes())}:${setLeadingZero(
    converted.getSeconds()
  )}Z`;
};

export const setLeadingZero = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

/**
 * Removed Z from string make sure its not manipulating timezone while new Date
 * @param {string} dateTime -string which need to be removed
 * @returns {string} formatted str
 */
export const removeZforISOString = (dateTime: string): string => {
  return dateTime && dateTime.toString().endsWith("Z")
    ? dateTime.replace("Z", "")
    : dateTime;
};

export const removeTZforISOString = (dateTime: string): string => {
  const modified = removeZforISOString(dateTime);
  return modified && modified.toString().indexOf("T")
    ? modified.replace("T", " ")
    : modified;
};

export const removeTforISOString = (dateTime: string): string => {
  return dateTime && dateTime.toString().indexOf("T")
    ? dateTime.replace("T", " ")
    : dateTime;
};

/**
 * Removed T from string make sure its not manipulating timezone while new Date
 * @param {TViaPoints[]} viaPoints -string which need to be removed
 * @returns {TViaPoints[]} formatted str
 */
export const formatVPPayload = (viaPoints) => {
  if (!viaPoints.length) return [];

  return viaPoints.map((vp, index) => {
    return {
      ...vp,
      point_latitude: vp.point_latitude.toString(),
      point_longitude: vp.point_longitude.toString(),
      planned_departure_time: vp.planned_departure_time
        ? removeTZforISOString(isoFormatDateTime(vp.planned_departure_time))
        : null,
      actual_departure_time: vp.actual_departure_time
        ? removeTZforISOString(isoFormatDateTime(vp.actual_departure_time))
        : null,
      planned_arrival_time:
        index > 0
          ? removeTZforISOString(isoFormatDateTime(vp.planned_arrival_time))
          : null,
    };
  });
};

export const minToTime = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  return `${hours > 0 ? hours + "h " : ""}${minutes}m`;
};

export const formatDateDifference = (startDate, endDate) => {
  const daysDiff = differenceInDays(endDate, startDate);
  const hoursDiff = differenceInHours(endDate, startDate);
  const minutesDiff = differenceInMinutes(endDate, startDate);

  const remainingHours = hoursDiff - daysDiff * 24;
  const remainingMinutes =
    minutesDiff - daysDiff * 24 * 60 - remainingHours * 60;

  return `${daysDiff} days, ${remainingHours} hours, ${remainingMinutes} minutes`;
};

/**
 * Generates tomorrows date and sets time to 9:00 AM
 * @returns {Date} tomorrow
 */
export const getTomorrowAtNine = () => {
  const today = new Date();

  // Get tomorrow's date
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Set the time to 9:00 AM
  tomorrow.setHours(9, 0, 0, 0);

  return tomorrow;
};

/**
 * Checks if a date is before or equal another date.
 * @param {Date} date1 First date
 * @param {Date} date2 second date
 * @returns {boolean} result
 */
export const isBeforeOrEqual = (date1, date2) => {
  return isBefore(date1, date2) || isSameDay(date1, date2);
};

/**
 * Convert days to milliseconds
 * @param {number} days days
 * @returns {number} milliseconds
 */
export const daysToMilliseconds = (days: number): number => {
  return days * 24 * 60 * 60 * 1000;
};

/**
 * Finds all dates within a date range that fall on the specified weekdays,
 * starting from the next week after `startDate`, with the ability to skip weeks in between.
 * A week is defined as Sunday to Saturday.
 * @param {Date} startDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @param {number[]} weekdays - Array of selected weekdays (0 for Sunday, 1 for Monday, etc.).
 * @param {number} [skip] - Number of weeks to skip. 0 means no skipping (i.e., select every week).
 * @returns {Date[]} Array of dates that match the selected weekdays and skip criteria.
 * @example
 * // Example 1
 * const result = findWeekdaysInRange(new Date('2024-09-27'), new Date('2024-10-15'), [2, 5], 2);
 * console.log(result); // [2024-10-01, 2024-10-04, 2024-10-15]
 * @example
 * // Example 2
 * const result = findWeekdaysInRange(new Date('2024-01-01'), new Date('2024-01-31'), [1, 3], 1);
 * console.log(result); // [2024-01-08, 2024-01-10, 2024-01-22, 2024-01-24]
 */
export const findWeekdaysInRange = (
  startDate: Date,
  endDate: Date,
  weekdays: number[],
  skip = 0
): Date[] => {
  // Start from the Sunday of the next week after the startDate
  const nextSunday = addDays(startOfWeek(startDate, { weekStartsOn: 0 }), 7);

  // Get all dates between nextSunday and endDate
  const allDates = eachDayOfInterval({
    start: nextSunday,
    end: endDate,
  });

  // Filter to find matching weekdays and apply the skip condition
  return allDates.filter((date) => {
    // Check if the current day is one of the selected weekdays
    const isSelectedWeekday = weekdays.includes(getDay(date));

    if (!isSelectedWeekday) {
      return false; // Skip if it's not a selected weekday
    }

    // Calculate the week difference from the nextSunday
    const weekDifference = differenceInCalendarWeeks(date, nextSunday, {
      weekStartsOn: 0,
    });

    // Check if this week matches the skip criteria (i.e., only include weeks that match the skip pattern)
    return weekDifference % skip === 0;
  });
};

/**
 * Finds a specified number of dates that fall on the selected weekdays,
 * starting from the next week after `startDate`, with the ability to skip weeks in between.
 * A week is defined as Sunday to Saturday.
 * @param {Date} startDate - The start date of the range.
 * @param {number[]} weekdays - Array of selected weekdays (0 for Sunday, 1 for Monday, etc.).
 * @param {number} occurence - Number of matching dates to find.
 * @param {number} [skip] - Number of weeks to skip. 0 means no skipping (i.e., select every week).
 * @returns {Date[]} Array of dates that match the selected weekdays and skip criteria.
 * @example
 * // Example 1
 * const result = findWeekdaysInRange(new Date('2024-09-27'), [2, 5], 2, 2);
 * console.log(result); // [2024-10-01, 2024-10-04]
 * @example
 * // Example 2
 * const result = findWeekdaysInRange(new Date('2024-01-01'), [1, 3], 4, 1);
 * console.log(result); // [2024-01-08, 2024-01-10, 2024-01-22, 2024-01-24]
 */
export const findWeekdaysInOccurrence = (
  startDate: Date,
  weekdays: number[],
  occurence: number,
  skip = 0
): Date[] => {
  // Start from the Sunday of the next week after the startDate
  const nextSunday = addDays(startOfWeek(startDate, { weekStartsOn: 0 }), 7);

  const selectedDates: Date[] = [];

  let currentDate = nextSunday;

  // Keep finding dates until the desired number of occurrences is reached
  while (selectedDates.length < occurence) {
    // Get all days of the week (Sunday to Saturday) starting from currentDate
    const weekDates = eachDayOfInterval({
      start: currentDate,
      end: addDays(currentDate, 6),
    });

    // Filter dates that match the selected weekdays
    for (const date of weekDates) {
      if (selectedDates.length >= occurence) break;

      if (weekdays.includes(getDay(date))) {
        selectedDates.push(date);
      }
    }

    // Move to the next week, skipping the specified number of weeks
    currentDate = addDays(currentDate, skip * 7);
  }

  return selectedDates;
};

/**
 * Removed T from string make sure its not manipulating timezone while new Date
 * @param {TViaPoints[]} viaPoints -string which need to be removed
 * @returns {TViaPoints[]} formatted str
 */
export const formatViaPointForDirectOrder = (viaPoints) => {
  if (!viaPoints.length) return [];

  return viaPoints.map((vp, index) => {
    const pdt = vp.planned_departure_time
      ? identifyBETimeAndConvert(vp.planned_departure_time)
      : null;
    const pat = vp.planned_arrival_time
      ? identifyBETimeAndConvert(vp.planned_arrival_time)
      : null;
    return {
      ...vp,
      point_latitude: vp.point_latitude.toString(),
      point_longitude: vp.point_longitude.toString(),
      planned_departure_time: vp.planned_departure_time ? pdt : null,
      actual_departure_time: null,
      planned_arrival_time: index > 0 ? pat : null,
    };
  });
};

const identifyBETimeAndConvert = (datetime) => {
  if (
    typeof datetime === "string" &&
    datetime.includes("T") &&
    datetime.endsWith("Z")
  ) {
    return removeTZforISOString(
      isoFormatDateTime(utcToLocaleDateTime(removeTforISOString(datetime)))
    );
  }
  return removeTZforISOString(isoFormatDateTime(datetime));
};
