import { routerResolveWithTagQuery } from "@/utils/route.utils.ts";
import { Trip } from "@/stores/trip/types.ts";

export const regexPasswordValidation =
  /(.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*)/;

export const getObjKey = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

/**
 * Get name initials
 * @param name
 * @returns
 */
export const firstLastInitials = (name) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
  return initials;
};

/**
 * Split by character and get the first item
 */

export const splitAndGetFirstItem = (text: string, character: string) => {
  return text.split(character)[0];
};

export const getLatLngObjFromString = (latLong: string) => {
  if (latLong) {
    const latLngArr = latLong.split(",");
    return {
      lat: Number(latLngArr[0]),
      lng: Number(latLngArr[1]),
    };
  }
  return null;
};
export const splitAndMakeUpperCase = (text: string) => {
  if (text === "request_rejected") {
    const diffText = text.split("_");
    return (
      diffText[0].charAt(0).toUpperCase() +
      diffText[0].slice(1) +
      " " +
      diffText[1]
    );
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const rowPerPage = 10;
export const rowsPerPageOptions = [10, 20, 30, 50];

// convert number to 2 decimal places and if the 3rd decimal is greater than 5 2nd decimal will be increased by 1
export const convertToTwoDecimal = (number) => {
  const roundedNumber = Math.round(number * 100) / 100; // Round to 2 decimal places
  const decimals = (roundedNumber % 1).toFixed(3).split("."); // Extract decimals

  if (parseInt(decimals[1][2]) >= 5) {
    const adjustedNumber = Math.round((roundedNumber + 0.01) * 100) / 100; // Increase 2nd decimal by 0.01
    return adjustedNumber.toFixed(2);
  }

  return roundedNumber.toFixed(2);
};

export const countCharactersWithoutSpaces = (inputString: string) => {
  const stringWithoutSpaces = inputString.replace(/\s/g, "");

  // Count the number of characters in the string without spaces
  return stringWithoutSpaces.length;
};

/**
 * Route to trip details page
 * @param trip
 * @params {Trip} - Object
 */
export const routeToTripDetails = (trip: Trip) => {
  return routerResolveWithTagQuery(
    trip.trip_type === "NORMAL" ? "normal_trips" : "fixed_trips",
    trip.id,
    "id",
    { org_id: trip.org_id }
  );
};

//production release commit added

// map auto location input
export const searchCountries = ["no", "se", "dk", "uk"];

export const generateId = () => {
  const uniqueId = Math.random().toString(36).substr(2, 6).toUpperCase();
  return `FE-BB${uniqueId}`;
};

// Text truncate
export const truncateText = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

// single word convert in pascal case
export const toPascalCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatDistance = (distance: number) => {
  return `${Math.round(distance)} KM`;
};
